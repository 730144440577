// @flow

import { useQuery, } from 'react-apollo';
import { BLOG_SECTION_QUERY, } from '@haaretz/graphql';

import { useRouter, } from 'next/router';
import useArticleId from './useArticleId';
import usePreview from './usePreview';
import useLiveBlogQueryVariables from './useLiveBlogQueryVariables';

export default function useBlogSectionData() {
  const articleId = useArticleId();
  const { previewId, } = usePreview();
  const router = useRouter();
  const path = router.query.path;

  const id = articleId || previewId;
  const queryVariables = useLiveBlogQueryVariables(articleId);


  const { data, loading, error, } = useQuery(BLOG_SECTION_QUERY, {
    fetchPolicy: 'cache-first',
    variables: { model: id ? {
      id,
    } : { path, },
    ...queryVariables,
    },
    skip: !articleId,
  });

  if (loading) return null;
  if (error) {
    console.error('Blog section error', error);
    return null;
  }

  if (!data) return null;
  const blogSection = data?.Page?.slots?.article?.blogSection;

  return blogSection;
}
